import React from "react"
//import { Link } from "gatsby"
import Img from 'gatsby-image'
import Image from "../../image"

// s4n
// import "./../assets/sass/pages/_blog.scss"
// import axios from "axios"
import { injectIntl } from "gatsby-plugin-intl"
import { Carousel } from 'react-bootstrap'
import ProductCard from './../Product/card'



const ComponentStateless = (props) => {
  const { intl, products } = props
  const t = intl.formatMessage
  
  const disableHeader = props.disableHeader || false;
  const relatedProducts = products || []

  if (!relatedProducts.length) {
    return null;
  }

  return (
    <React.Fragment>
        {!disableHeader && 
            <div className="divider-new">
                <h3 className="h3-responsive font-weight-bold blue-text">
                    { t({ id: "soft4net_shop_product_related_related_products" }) }
                </h3>
            </div>
        }
        <section id="products" className="pb-4">
            {/* <p className="w-responsive mx-auto mb-5 dark-grey-text">Lorem ipsum dolor sit amet, consectetur
                adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur accusamus veniam.</p> */}
            <div id="multi-item-example" className="row">

                {false && <Carousel
                    // interval={null}
                    // indicators={false}
                >
                    {relatedProducts.map((product, index) => {
                        return (
                            <Carousel.Item>
                                {/* <img
                                    className="d-block w-100"
                                    src="holder.js/800x400?text=First slide&bg=373940"
                                    alt="First slide"
                                /> */}
                                {/* <Img fluid={ image.fluid } alt={image.alt} /> */}
                                {/* <Carousel.Caption>
                                    <h3>First slide label</h3>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </Carousel.Caption> */}

                                <ProductCard product={product} />
                            </Carousel.Item>
                        );
                    })}
                </Carousel>}

                {true && relatedProducts.map((product, index) => {
                    return (
                        <div className={`d-flex col-lg-3 col-md-6 mb-4`}>
                            <ProductCard product={product} />
                        </div>
                    )
                })}
            </div>
        </section>
    </React.Fragment>
  )
}

export default injectIntl(ComponentStateless)